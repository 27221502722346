import * as API from '../../index';
export default {
    //项目列表
    skinProductConfig:params =>{
        return API.POST('api/skinProductConfig/all',params)
    },
    //创建检测项目
    create:params =>{
        return API.POST('api/skinProductConfig/create',params)
    },
    // 编辑检测项目
    update:params =>{
        return API.POST('api/skinProductConfig/update',params)
    },
    //仪器编号
    skinInstrument: params => {
        return API.POST("api/skinInstrument/list", params)
    },
    //类型编号
    skinCategory: params => {
        return API.POST("api/skinCategory/all", params)
    },

}